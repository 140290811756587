import { FiltroDeTitulos } from "@/models/financeiro/Titulo";

export function limparFiltroDosCamposDeTitulos(): FiltroDeTitulos {
	return {
		pessoa: '',
		tipoDeCliente: '',
		dataInicioPeriodo: '',
		dataFimPeriodo: '',
		formaDePagamento: '',
		numeroDocumento: '',
		dataInicioPagamento: '',
		dataFimPagamento: '',
	}
}
