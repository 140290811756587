import type { PontoDeVendaComTurno } from "@/models"
import { Inject } from "inversify-props"
import type { PdvServiceAdapter } from "./adapter"

export class FindPdvUseCase {

	@Inject("PdvServiceAdapter")
	private pdvService!: PdvServiceAdapter

	async buscarPdvSemTurno(lojaId: string): Promise<PontoDeVendaComTurno[]> {
		return this.pdvService.buscarPdvSemTurno(lojaId)
	}
}