














import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'

@Component
export default class CheckboxButton extends Vue {
	@PropSync('value', { type: Boolean }) valor!: boolean
	@Prop({ type: Boolean }) indeterminate!: boolean
}

