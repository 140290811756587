






































































































import SeletorDeTipoDeCliente from '@/components/tabeladepreco/SeletorDeTipoDeCliente.vue'
import DatePicker from '@/components/ui/DatePicker.vue'
import { FormaDePagamento, SituacaoDoTitulo } from '@/models'
import type { FiltroDeTitulos } from '@/models'
import { limparFiltroDosCamposDeTitulos } from '@/shareds/titulo-shareds'
import { nextTick } from '@/shareds/utils'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({
	components: {
		DatePicker,
		SeletorDeTipoDeCliente,
	},
})
export default class CamposDeFiltrosDeTitulos extends Vue {
	@Prop({ type: Boolean, default: false }) carregando!: boolean
	@PropSync('value', {
		default: () => ({
			tipoDeCliente: '',
			pessoa: '',
			dataInicioPeriodo: [],
			dataFimPeriodo: [null, null],
			situacaoDoTitulo: '',
			formaDePagamento: false,
			loja: '',
			numeroDocumento: false,
			dataInicioPagamento: null,
			dataFimPagamento: null,
		}),
	})
	filtro!: FiltroDeTitulos

	mostra: boolean | null = null

	opcoesDeFormaDePagamento: FormaDePagamento[] = [
		'Dinheiro',
		'Cartão',
		'Voucher',
		'Crediário',
		'Link',
		'TEF Crédito',
		'TEF Débito',
		'TEF Pix',
		'Outros',
	]

	opcoesDeSituacaoDeTitulo: SituacaoDoTitulo[] = [
		'Aberto',
		'Pago',
		'Cancelado',
		'Estornado',
	]

	mostrar() {
		this.mostra = true
	}

	ocultar() {
		this.mostra = false
	}

	async aplicarFiltro() {
		await nextTick()
		this.$emit('buscar', this.filtro)
		this.ocultar()
	}

	limparFiltros() {
		this.$emit('limpar')
		this.$emit('input', limparFiltroDosCamposDeTitulos())
	}
}
